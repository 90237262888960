/* unplugin-vue-components disabled */import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, renderSlot as _renderSlot, resolveComponent as _resolveComponent } from "vue";
var _hoisted_1 = {
  class: "panel-menu2__main"
};
var _hoisted_2 = {
  class: "panel-menu2__title"
};
var _hoisted_3 = {
  key: 0,
  class: "panel-menu2__no-data"
};
var _hoisted_4 = ["onMouseenter", "onMouseleave", "onClick"];
var _hoisted_5 = {
  class: "panel-menu2__link-text"
};
var _hoisted_6 = {
  key: 0,
  class: "icon-right-arrow"
};
var _hoisted_7 = {
  class: "panel-menu2__foot"
};
export default {
  __name: 'PanelMenu2',
  props: {
    // 最上層
    topLevel: Boolean,
    // 是否顯示
    show: Boolean,
    // 勾選的項目
    checked: Array,
    // 上層是否有顯示
    isParentShow: Boolean,
    // link是否可選取
    linkCheckable: Boolean,
    // link是否單選
    linkCheckSingle: Boolean,
    // link是否只能選取同層
    linkCheckOnlySameParent: Boolean,
    // 第二層link是否只能選取同類型
    linkCheckOnlySameTemplateType: Boolean,
    // 標題
    title: String,
    // 完整的選單列表
    fullList: Array,
    // 選單列表
    list: Array,
    // 上層object
    parent: [Object, String],
    // 自訂的額外參數
    extendedProp: null,
    // backdrop是否在body
    onBody: {
      type: Boolean,
      default: true
    }
  },
  emits: ['init', 'update:show', 'linkClick', 'enterMenu', 'leaveMenu', 'checkedUpdate', 'beforeCheckToggle', 'update', 'update:checked'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    /**
     * 打開選單條件 - hover
     *
     * - 上層被hover項目有子層
     * - 選單被hover
     * - 有項目的checked狀態是true
     */

    /**
     * 打開選單條件 - click
     *
     * - 上層被checked項目有子層
     * - 有項目的checked狀態是true
     */

    var refRoot = ref(null);
    var hasAnySublist = computed(function () {
      if (!props.list) return false;
      return props.list.some(function (item) {
        return item.list;
      });
    });
    var cData = reactive({
      hovered: false,
      childHovered: false,
      childInstance: '',
      currentItem: '',
      anyItemChecked: computed(function () {
        if (!props.list) return;
        return props.list.some(function (item) {
          return item.checked;
        });
      }),
      nowItemCheckedTemplateType: computed(function () {
        var _props$list$find;

        if (!props.list) return;
        return (_props$list$find = props.list.find(function (item) {
          return item.checked && item.template_type;
        })) === null || _props$list$find === void 0 ? void 0 : _props$list$find.template_type;
      }) // showSubMenu: false,
      // enterSubMenu: false,

    });
    var isShow = computed(function () {
      // return props.show || (props.parent && props.parent.list) || cData.hovered || cData.anyItemChecked
      if (props.topLevel) {
        return props.show;
      } else {
        if (!props.isParentShow) {
          return false;
        }

        return props.show || props.parent && props.parent.list || cData.hovered || cData.anyItemChecked;
      }
    });
    watch(function () {
      return props.parent;
    }, function () {});
    var timeout = '';

    function close() {
      cData.hovered = false;
      emit('update:show', false);
    }

    function getSelected(list) {
      if (!list) return;
      var result = [];
      list.forEach(function (item) {
        if (item.list) {
          result = result.concat(getSelected(item.list));
        } else {
          if (item.checked) {
            result.push(item);
          }
        }
      });
      return result;
    }

    function uncheckAll(list) {
      if (!list) return;
      list.forEach(function (item) {
        item.checked = false;

        if (item.list) {
          uncheckAll(item.list);
        }
      });
    }

    function checkLeave() {
      if (timeout) clearTimeout(timeout); // 如果hover選項，則此timeout會在onlinkenter被取消

      timeout = setTimeout(function () {
        nextTick(function () {
          if (!cData.childHovered) {
            cData.currentItem = '';
          }
        });
      }, 0);
    }

    function isAnyChildChecked(item) {
      if (!item.list) return;
      var result = item.list.find(function (item) {
        return item.checked;
      });

      if (result) {
        return true;
      }
    }

    var hasChild = function hasChild(list) {
      if (!props.topLevel) {
        return false;
      }

      if (!list) return false;
      return list === null || list === void 0 ? void 0 : list.some(function (items) {
        var _items$list;

        return items === null || items === void 0 ? void 0 : (_items$list = items.list) === null || _items$list === void 0 ? void 0 : _items$list.some(function (item) {
          return item.checked;
        });
      });
    };

    function onEnterMenu() {// emit('enterMenu', props.parent)
      // cData.hovered = true;
    }

    function onLeaveMenu() {// emit('leaveMenu')
      // cData.hovered = false;
    }

    function onChildEnter() {// console.log('child hover')
      // cData.childHovered = true;
    }

    function onChildLeave() {// cData.childHovered = false;
      // checkLeave();
    }

    function onLinkEnter(item) {// 清掉leave的timeout
      // if ( timeout ) clearTimeout(timeout);
      // cData.currentItem = item;
    }

    function onLinkLeave(item) {// checkLeave()
    }

    function onLinkClick(dataObj) {
      // 最後一層才能點
      if (!dataObj.item.list) {
        if (props.linkCheckSingle) {
          uncheckAll(props.list);
        }

        emit('beforeCheckToggle');
        dataObj.item.checked = !dataObj.item.checked;
      }

      if (cData.currentItem.id == dataObj.item.id) {
        cData.currentItem = '';
      } else {
        // 如果是最後一層必需要是checked才能是current item
        if (!dataObj.item.list) {
          if (dataObj.item.checked) {
            cData.currentItem = dataObj.item;
          }
        } else {
          cData.currentItem = dataObj.item;
        }
      }

      if (props.fullList || props.list) {
        var checked = getSelected(props.fullList || props.list);
        emit('checkedUpdate', checked);
        emit('update:checked', checked ? checked.map(function (item) {
          return item.id;
        }) : []);
      }

      emit('linkClick', dataObj);
    }

    function linkCheckSingleScan() {
      if (props.linkCheckSingle) {
        uncheckAll(props.list);
      }
    }

    function removeCurrentItem() {
      cData.currentItem = '';
    } // 勾選項目


    function _checkItem(id, list) {
      if (!list) return;
      list.forEach(function (item) {
        if (item.id == id) {
          item.checked = true;
        }

        if (item.list) {
          _checkItem(id, item.list);
        }
      });
    } // 勾選項目


    function checkItem(id, list) {
      var list = list || props.list;
      list.forEach(function (item) {
        if (item.id == id) {
          item.checked = true;
        }

        if (item.list) {
          checkItem(id, item.list);
        }
      });
      var checked = getSelected(props.fullList || props.list);
      emit('checkedUpdate', checked);
      emit('update:checked', checked ? checked.map(function (item) {
        return item.id;
      }) : []);
    } // 取消勾選項目


    function unCheckItem(id, list) {
      var list = list || props.list;
      list.forEach(function (item) {
        if (item.id == id) {
          item.checked = false;
        }

        if (item.list) {
          unCheckItem(id, item.list);
        }
      });
      var checked = getSelected(props.fullList || props.list);
      emit('checkedUpdate', checked);
      emit('update:checked', checked ? checked.map(function (item) {
        return item.id;
      }) : []);
    }

    function syncChecked() {
      uncheckAll(props.fullList || props.list);

      if (Array.isArray(props.checked)) {
        props.checked.forEach(function (id) {
          _checkItem(id, props.fullList || props.list);
        });
      }
    }

    function isItemHasId(item, id) {
      if (item.id == id) {
        return true;
      }

      if (item.list) {
        var result = item.list.some(function (item) {
          return isItemHasId(item, id);
        });
        return result;
      }
    }

    function getChained(list, id) {
      if (!list) return;
      var result = [];
      list.forEach(function (item) {
        if (isItemHasId(item, id)) {
          result.push(item);
        }

        if (item.list) {
          result = result.concat(getChained(item.list, id));
        }
      });
      return result;
    }

    function getChainedItem() {
      if (Array.isArray(props.checked)) {
        return getChained(props.list, props.checked[0]);
      }
    }

    watch(function () {
      return props.show;
    }, function () {
      if (props.show === false) {
        cData.currentItem = {};
      }
    });
    watch(function () {
      return cData.currentItem;
    }, function () {
      nextTick(function () {
        if (!cData.currentItem) {// cData.showSubMenu = false;
        }
      });
    }); // 已勾選的狀態

    watch(function () {
      return props.checked;
    }, function () {
      syncChecked();
    });
    syncChecked();
    emit('init', {
      removeCurrentItem: removeCurrentItem,
      getChainedItem: getChainedItem,
      unCheckItem: unCheckItem
    });
    return function (_ctx, _cache) {
      var _component_PanelMenu2 = _resolveComponent("PanelMenu2", true);

      return _openBlock(), _createElementBlock("div", {
        ref_key: "refRoot",
        ref: refRoot,
        class: _normalizeClass(["panel-menu2", {
          'panel-menu2_show': _unref(isShow),
          'panel-menu2_link-checkeable': __props.linkCheckable
        }]),
        onMouseenter: onEnterMenu,
        onMouseleave: onLeaveMenu
      }, [__props.onBody ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "body"
      }, [__props.topLevel ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["panel-menu2__backdrop", [_unref(isShow) ? 'panel-menu2__backdrop_show' : '']]),
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return close();
        })
      }, null, 2)) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [__props.topLevel ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["panel-menu2__backdrop", [_unref(isShow) ? 'panel-menu2__backdrop_show' : '']]),
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return close();
        })
      }, null, 2)) : _createCommentVNode("", true)], 64)), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(__props.title), 1), !__props.list || Array.isArray(__props.list) && !__props.list.length ? (_openBlock(), _createElementBlock("p", _hoisted_3, " 尚無資料 ")) : _createCommentVNode("", true), _createElementVNode("div", {
        class: _normalizeClass(["panel-menu2__body", {
          'panel-menu2__body--same-parent': __props.topLevel && __props.linkCheckOnlySameParent && hasChild(__props.list)
        }])
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, function (item, idx) {
        return _openBlock(), _createElementBlock("div", {
          key: idx,
          class: _normalizeClass(["panel-menu2__link", [item.checked || isAnyChildChecked(item) ? 'panel-menu2__link_active' : '', __props.linkCheckOnlySameTemplateType && item.template_type && _unref(cData).nowItemCheckedTemplateType && _unref(cData).nowItemCheckedTemplateType !== item.template_type ? 'panel-menu2__link_disabled' : '']]),
          onMouseenter: function onMouseenter($event) {
            return onLinkEnter(item);
          },
          onMouseleave: function onMouseleave($event) {
            return onLinkLeave(item);
          },
          onClick: function onClick($event) {
            return onLinkClick({
              item: item,
              parent: __props.extendedProp
            });
          }
        }, [_createElementVNode("div", _hoisted_5, _toDisplayString(item.topic), 1), item.list ? (_openBlock(), _createElementBlock("i", _hoisted_6)) : _createCommentVNode("", true)], 42, _hoisted_4);
      }), 128))], 2), _createElementVNode("div", _hoisted_7, [_renderSlot(_ctx.$slots, "foot")])]), _unref(hasAnySublist) ? (_openBlock(), _createBlock(_component_PanelMenu2, {
        key: 2,
        list: _unref(cData).currentItem.list,
        "full-list": __props.list,
        parent: _unref(cData).currentItem,
        "is-parent-show": _unref(isShow),
        "extended-prop": _unref(cData).currentItem,
        "link-checkable": __props.linkCheckable,
        "link-check-single": __props.linkCheckSingle,
        "link-check-only-same-template-type": __props.linkCheckOnlySameTemplateType,
        onEnterMenu: onChildEnter,
        onLeaveMenu: onChildLeave,
        onLinkClick: _cache[2] || (_cache[2] = function ($event) {
          return _ctx.$emit('linkClick', $event);
        }),
        onCheckedUpdate: _cache[3] || (_cache[3] = function ($event) {
          return _ctx.$emit('checkedUpdate', $event);
        }),
        "onUpdate:checked": _cache[4] || (_cache[4] = function ($event) {
          return _ctx.$emit('update:checked', $event);
        }),
        onInit: _cache[5] || (_cache[5] = function ($event) {
          return _unref(cData).childInstance = $event;
        }),
        onBeforeCheckToggle: linkCheckSingleScan
      }, null, 8, ["list", "full-list", "parent", "is-parent-show", "extended-prop", "link-checkable", "link-check-single", "link-check-only-same-template-type"])) : _createCommentVNode("", true)], 34);
    };
  }
};